import React, { useState } from "react";
import { formatNumber, getAuthUser, getCurrentDate, investInIPO } from "../../../firebase/firestore";
import CurrencyInput from "react-currency-input-field";
import "./style.css"; 

export default function InvestIpoModal({ isOpen, onClose, ipo }) {
  const [investmentAmount, setInvestmentAmount] = useState(0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userId = getAuthUser();

  const handleInvestInIpo = async () => {
    if (!investmentAmount || investmentAmount < ipo.minInvestment) {
      setError(
        `Investment amount must be greater than minimum investment value of $${ipo.minInvestment}`
      );
      setTimeout(() => setError(""), 4000);
      return;
    }

    const investmentData = {
      amountInvested: parseFloat(investmentAmount),
      logo: ipo.logo,
      name: ipo.name,
      expectedDate: ipo.expectedDate,
      sharePrice: ipo.sharePrice,
      expListingPrice: ipo.expListingPrice,
      date: getCurrentDate(),
      minInvestment: ipo.minInvestment,
      numberOfShares: numberOfShares,
    };
    setIsLoading(true);
    try {
      await investInIPO(userId, investmentData);
      setMessage("Investment request has been sent.");
      setTimeout(() => {
        setMessage("");
        setInvestmentAmount("");
        onClose();
      }, 2000);
    } catch (error) {
      setError(
        `There was an issue sending your investment request. Try again later.`
      );
      console.error(error.message);
      setTimeout(() => setError(""), 2000);
    }

    setIsLoading(false);
  };

  if (!isOpen) return null;

  const totalCost = investmentAmount * ipo.sharePrice;
  const numberOfShares =
  (Math.ceil((investmentAmount / ipo.sharePrice) * 100) / 100).toFixed(2);

  return (
    <div className="invest_ipo_overlay" onClick={(e) => e.stopPropagation()}>
      <div className="invest_ipo_modal">
        <div className="section_header">
          <div className="logo">
            <img src={ipo.logo} alt={`${ipo.name} Logo`} />
          </div>
          <h2 className="title">{ipo.name}</h2>
          <div className="subtitle">{/* <span>{ipo.expIpoDate}</span> */}</div>
        </div>
        <div className="section_body">
          <div className="more_dets">
            <p className="bold_text">IPO Expected Date:</p>
            <p className="reg_text">{ipo.expectedDate}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">IPO Share Price: </p>
            <p className="reg_text">$ {formatNumber(ipo.sharePrice)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Expected Listing Price:</p>
            <p className="reg_text">$ {formatNumber(ipo.expListingPrice)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Minimum Investment Amount:</p>
            <p className="reg_text">$ {formatNumber(ipo.minInvestment)}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Number of Shares:</p>
            <p className="reg_text">{numberOfShares}</p>
          </div>
          <div className="more_dets">
            <p className="bold_text">Total Cost:</p>
            <p className="reg_text">$ {formatNumber(totalCost)}</p>
          </div>
          <div className="input_group">
            <label htmlFor="title">Investment Amount:</label>
            <CurrencyInput
              decimalSeparator="."
              prefix="$"
              name="investmentAmount"
              placeholder="$0.00"
              defaultValue={investmentAmount} 
              decimalsLimit={2}
              onValueChange={(value) => {
                const formattedValue = parseFloat(value).toFixed(2);
                setInvestmentAmount(parseFloat(formattedValue)); 
              }}
            />
          </div>
        </div>
        {message && <p className="success_msg">{message}</p>}
        {error && <p className="error_msg">{error}</p>}
        <div className="buttons_wrap">
          <button onClick={handleInvestInIpo} className="submit_btn">
            Invest
          </button>
        {isLoading && (
          <div className="spinner" style={{ margin: "0 auto" }}></div>
        )}
          <button onClick={onClose} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
