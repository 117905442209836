import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { auth } from '../../../firebase/firebase';
import logo from '../../../assets/white_logo.png';
import './style.css';

export default function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const validatePassword = (pass) => {
    const regex = /^(?=.*\d)(?=.*[\W_]).{8,}$/;
    return regex.test(pass);
  };

  const setErrorWithTimeout = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => setError(""), 4000);
  };

  const setMessageWithTimeout = (successMessage) => {
    setMessage(successMessage);
    setTimeout(() => setMessage(""), 4000);
  };

  const validatePasswords = () => {
    if (newPassword !== confirmPassword) {
      setErrorWithTimeout("New password and confirm password do not match.");
      return false;
    }
    if (!validatePassword(newPassword)) {
      setErrorWithTimeout("Password must be at least 8 characters long, must contain at least one number and a special character.");
      return false;
    }
    return true;
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validatePasswords()) {
      setIsLoading(false);
      return;
    }

    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);

    try {
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setMessageWithTimeout("Password updated successfully.");
      navigate('/');
    } catch (err) {
      setErrorWithTimeout(err.message || "Error updating password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="changePassword_page">
      <div className="changePassword_form">
        <img src={logo} alt="Logo" className="logo" />
        <div className="header">
          <h1 className="title">Change Password</h1>
          <p className="subtitle">Please enter your current and new passwords.</p>
        </div>
        <form className="form_wrap" onSubmit={handleChangePassword}>
          <input
            type="password"
            name="currentPassword"
            placeholder="Current Password"
            className="input_field"
            value={currentPassword}
            onChange={({ target: { value } }) => setCurrentPassword(value)}
            required
            disabled={isLoading}
          />
          <input
            type="password"
            name="newPassword"
            placeholder="New Password"
            className="input_field"
            value={newPassword}
            onChange={({ target: { value } }) => setNewPassword(value)}
            required
            disabled={isLoading}
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm New Password"
            className="input_field"
            value={confirmPassword}
            onChange={({ target: { value } }) => setConfirmPassword(value)}
            required
            disabled={isLoading}
          />
          {isLoading ? (
            <button className="change_btn" type="submit" disabled>
              <div className="spinner"></div>
            </button>
          ) : (
            <button className="change_btn" type="submit">Change Password</button>
          )}
          <div className="info">
            {message && <p className="success_msg">{message}</p>}
            {error && <p className="error_msg">{error}</p>}
          </div>
        </form>
      </div>
    </section>
  );
}
