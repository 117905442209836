import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import {
  confirmPasswordReset,
  getAuth,
  verifyPasswordResetCode,
} from "firebase/auth";
import ResetPasswordForm from "./ResetPasswordForm";
import ResetPasswordSuccess from "./RestPasswordSuccess";

// Define action types
const ACTIONS = {
  PASSWORD_CHANGE: "PASSWORD_CHANGE",
  RESET_FORM: "RESET_FORM",
  SHOW_MESSAGE: "SHOW_MESSAGE",
  SHOW_ERROR: "SHOW_ERROR",
  LOADING: "LOADING",
};

const initialState = {
  password: "",
  confirmPassword: "",
  message: "",
  error: "",
  stage: "form",
  loading: false,
};

function reducer(state, action) {
  switch (action.type) {
    case ACTIONS.PASSWORD_CHANGE:
      return { ...state, [action.field]: action.value };
    case ACTIONS.RESET_FORM:
      return { ...initialState, stage: "form", loading: false };
    case ACTIONS.SHOW_MESSAGE:
      return {
        ...state,
        stage: "success",
        message: action.message,
        loading: false,
      };
    case ACTIONS.SHOW_ERROR:
      return { ...state, stage: "form", error: action.error, loading: false };
    case ACTIONS.LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
}

function validatePassword(pass) {
  const regex = /^(?=.*\d)(?=.*[\W_]).{8,}$/;
  return regex.test(pass);
}

export default function ResetPassword() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();

  async function verifyPasswordResetToken(token) {
    try {
      const userEmail = await verifyPasswordResetCode(auth, token);
      return userEmail;
    } catch (error) {
      dispatch({ type: ACTIONS.SHOW_ERROR, error: "Error verifying the password reset token." });
      console.error("Error verifying the password reset token:", error);
      return null;
    }
  }

  async function updatePasswordForUser(code, newPassword) {
    try {
      await confirmPasswordReset(auth, code, newPassword);
      dispatch({
        type: ACTIONS.SHOW_MESSAGE,
        message: "Password updated successfully.",
      });
    } catch (error) {
      dispatch({ type: ACTIONS.SHOW_ERROR, error: "Error updating the password. Please try again." });
      console.error("Error updating password:", error);
    }
  }

  const validatePasswords = () => {
    if (state.password !== state.confirmPassword) {
      dispatch({ type: ACTIONS.SHOW_ERROR, error: "New password and confirm password do not match." });
      return false;
    }
    if (!validatePassword(state.password)) {
      dispatch({ type: ACTIONS.SHOW_ERROR, error: "Password must meet the specified criteria." });
      return false;
    }
    return true;
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    dispatch({ type: ACTIONS.LOADING });

    if (!validatePasswords()) {
      return;
    }

    const resetPasswordToken = new URLSearchParams(window.location.search).get("oobCode");

    if (!resetPasswordToken) {
      dispatch({ type: ACTIONS.SHOW_ERROR, error: "Password reset token is missing or invalid." });
      return;
    }

    try {
      const userEmail = await verifyPasswordResetToken(resetPasswordToken);
      if (userEmail) {
        await updatePasswordForUser(resetPasswordToken, state.password);
      }
    } catch (error) {
      console.error("Error during password reset:", error);
    }
  };

  return (
    <>
      {state.stage === "form" && (
        <ResetPasswordForm
          state={state}
          dispatch={dispatch}
          handleChangePassword={handleChangePassword}
          showTooltip={showTooltip}
          setShowTooltip={setShowTooltip}
          ACTIONS={ACTIONS}
        />
      )}
      {state.stage === "success" && (
        <ResetPasswordSuccess state={state} navigate={navigate} />
      )}
    </>
  );
}