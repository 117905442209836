import React, { useEffect, useState, useRef } from "react";
import "firebase/firestore";
import BondsTable from "../Bonds/BondsTable";
import TermsTable from "../FixedTerm/TermTable";
import IposTable from "../IPOs/IposTable";
import "./style.css";
import {
  formatNumber,
  getAuthUser,
  getBondsHoldings,
  getUserFixedTerm,
  getUserIpos,
} from "../../firebase/firestore";
import { BsCardChecklist } from "react-icons/bs";
import { CiMoneyBill, CiMoneyCheck1 } from "react-icons/ci";
import { PiBriefcase } from "react-icons/pi";

// export default function MyAccounts() {
//   // Initialize accounts with default values
//   const defaultAccounts = [
//     { label: "Easy Access", amount: 0 },
//     { label: "1 Year Fixed Saver", amount: 0 },
//     { label: "3 Years Fixed Saver", amount: 0 },
//     { label: "5 Years Fixed Saver", amount: 0 },
//   ];

//   const [accounts, setAccounts] = useState(defaultAccounts);
//   const [isLoading, setIsLoading] = useState(false);

//   const [activeTab, setActiveTab] = useState("bonds");
//   const bondsRef = useRef(null);
//   const termsRef = useRef(null);
//   const iposRef = useRef(null);
//   const [indicatorStyle, setIndicatorStyle] = useState({});

//   useEffect(() => {
//     const activeRef = activeTab === "bonds" ? bondsRef : activeTab === "terms" ? termsRef : iposRef;
//     if (activeRef.current) {
//       const width = activeRef.current.offsetWidth;
//       const left = activeRef.current.offsetLeft;
//       setIndicatorStyle({ width, left });
//     }
//   }, [activeTab]);

//   const userId = getAuthUser();

//   useEffect(() => {
//     setIsLoading(true);
//     if (userId) {
//       const accountTypeRef = collection(db, "users", userId, "accountTypes");
//       const unsubscribe = onSnapshot(accountTypeRef, (snapshot) => {
//         const newAccounts = [...defaultAccounts]; // Assuming defaultAccounts is defined elsewhere
//         let totalValue = 0;

//         snapshot.forEach((doc) => {
//           const index = newAccounts.findIndex(
//             (account) => account.label === doc.id
//           );

//           if (index !== -1) {
//             const balance = parseFloat(doc.data().amount) || 0; // Convert balance to float and default to 0 if it's not a number
//             newAccounts[index].amount = balance;
//             totalValue += balance;
//           }
//         });

//         setAccounts(newAccounts);

//         // Update the total value in secure local storage
//         if (secureLocalStorage.getItem("totalAccountValue")) {
//           secureLocalStorage.removeItem("totalAccountValue");
//         }

//         secureLocalStorage.setItem("totalAccountValue", totalValue);

//         setIsLoading(false);
//       });

//       return () => {
//         unsubscribe();
//       };
//     }
//   }, [userId]);

//   return (
//     <div className="myAccounts_page">
//       <div className="section_body">
//         <div className="account_cards">
//           {accounts.map((account, index) => (
//             <div className="account_card" key={index}>
//               <div className="card_icon">
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   width="60"
//                   viewBox="0 0 74 75"
//                 >
//                   <g
//                     id="Rectangle_12"
//                     data-name="Rectangle 12"
//                     fill="none"
//                     stroke="#fff"
//                     strokeWidth="2"
//                   >
//                     <rect width="74" height="75" rx="15" stroke="none"></rect>
//                     <rect
//                       x="1"
//                       y="1"
//                       width="72"
//                       height="73"
//                       rx="14"
//                       fill="none"
//                     ></rect>
//                   </g>
//                   <g
//                     id="Group_16"
//                     data-name="Group 16"
//                     transform="translate(1997.478 1142.783)"
//                   >
//                     <path
//                       id="Path_7"
//                       data-name="Path 7"
//                       d="M-1980.519-1092.268c-.854,0-1.621.006-2.388,0-.6-.006-.83-.233-.836-.834-.007-.767,0-1.534,0-2.391-.76,0-1.5,0-2.239,0-.793,0-.986-.193-.986-.979q0-12.038,0-24.076c0-.8.178-.974.985-.974h44.575c.789,0,.97.187.971.988,0,.725,0,1.45,0,2.236.831,0,1.611-.005,2.393,0,.607.005.825.225.83.835.006.767,0,1.534,0,2.389.78,0,1.539,0,2.3,0,.729,0,.926.194.926.914q0,12.1,0,24.192c0,.717-.205.925-.92.925q-22.345,0-44.69,0c-.716,0-.918-.207-.92-.925C-1980.521-1090.713-1980.519-1091.457-1980.519-1092.268Zm-5.069-11.273a7.5,7.5,0,0,1,6.658,6.652h16.3q6.825,0,13.649.006c.375,0,.556-.041.621-.5a7.262,7.262,0,0,1,6.1-6.044c.346-.049.441-.173.439-.506q-.015-4.579,0-9.158c0-.334-.092-.45-.44-.5a7.094,7.094,0,0,1-4.238-2.235,7.388,7.388,0,0,1-1.946-4.269h-30.517a1.479,1.479,0,0,0-.062.2,7.262,7.262,0,0,1-6.166,6.309c-.374.054-.415.218-.413.525.009,1.785,0,3.571,0,5.356Zm46.955,9.873v-23.224h-1.8v.7q0,9.79,0,19.581c0,.983-.134,1.114-1.139,1.114h-40.74v1.827Zm-40.489,3.2h43.724v-23.183h-1.813v20.245c0,1.025-.119,1.142-1.164,1.142h-40.747Zm-6.437-11.57v5.121h5.119A5.783,5.783,0,0,0-1985.56-1102.042Zm38.579,5.153h5.163v-5.163A5.833,5.833,0,0,0-1946.981-1096.889ZM-1985.558-1115c2.373.036,5.071-2.657,5.117-5.113h-5.117Zm43.721-5.109h-5.139a5.706,5.706,0,0,0,5.139,5.092Z"
//                       fill="#fff"
//                     ></path>
//                     <path
//                       id="Path_8"
//                       data-name="Path 8"
//                       d="M-1774.256-1045.419a8.9,8.9,0,0,1-8.906-8.924,8.9,8.9,0,0,1,8.937-8.891,8.9,8.9,0,0,1,8.877,8.894A8.9,8.9,0,0,1-1774.256-1045.419Zm-.014-16.4a7.475,7.475,0,0,0-7.479,7.509,7.489,7.489,0,0,0,7.51,7.483,7.492,7.492,0,0,0,7.48-7.514A7.478,7.478,0,0,0-1774.27-1061.823Z"
//                       transform="translate(-189.449 -54.183)"
//                       fill="#fff"
//                     ></path>
//                     <path
//                       id="Path_9"
//                       data-name="Path 9"
//                       d="M-1704.7-1012.274v-2.531a2.787,2.787,0,0,1-2.278-1.017,2.675,2.675,0,0,1-.585-1.93c.08-1.382,1.154-2.35,2.844-2.5.084-.514-.082-1.275.787-1.229.734.039.625.687.643,1.082a11.412,11.412,0,0,1,1.776.728,2.375,2.375,0,0,1,1.1,2.094.661.661,0,0,1-.623.756c-.444.04-.7-.227-.767-.677-.148-.939-.55-1.282-1.469-1.257v2.5c1.854.243,2.847,1.189,2.857,2.7.011,1.561-.969,2.528-2.856,2.791-.058.514.079,1.19-.746,1.222-.466.018-.637-.3-.689-1.211-.284-.052-.582-.091-.871-.164a2.667,2.667,0,0,1-1.995-2.513c0-.464.208-.762.651-.787s.679.255.743.707C-1706.045-1012.622-1705.641-1012.281-1704.7-1012.274Zm-.013-3.955v-2.539c-.884.005-1.444.484-1.463,1.222S-1705.636-1016.28-1704.709-1016.228Zm1.448,3.944a1.267,1.267,0,0,0,1.466-1.254,1.276,1.276,0,0,0-1.466-1.244Z"
//                       transform="translate(-259.72 -92.992)"
//                       fill="#fff"
//                     ></path>
//                   </g>
//                 </svg>
//               </div>
//               <div className="card_texts">
//                 <h4 className="card_label">{account.label}</h4>
//                 {isLoading ? (
//                   <div className="spinner"></div>
//                 ) : (
//                   <strong className="card_amount">$ {formatNumber(account.amount)}</strong>
//                 )}
//               </div>
//             </div>
//           ))}
//         </div>
//         <section className="holdings_section">
//           <div className="tab_header">
//             <h2
//               ref={bondsRef}
//               className={`title ${activeTab === "bonds" ? "active" : ""}`}
//               onClick={() => setActiveTab("bonds")}
//             >
//               My Bonds
//             </h2>
//             <h2
//               ref={termsRef}
//               className={`title ${activeTab === "terms" ? "active" : ""}`}
//               onClick={() => setActiveTab("terms")}
//             >
//               My Term Deposit
//             </h2>
//             <h2
//               ref={iposRef}
//               className={`title ${activeTab === "ipos" ? "active" : ""}`}
//               onClick={() => setActiveTab("ipos")}
//             >
//               My IPOs
//             </h2>
//             <div className="indicator" style={indicatorStyle}></div>
//           </div>
//           {activeTab === "bonds" && <BondsTable />}
//           {activeTab === "terms" && <TermsTable />}
//           {activeTab === "ipos" && <IposTable />}
//         </section>
//       </div>
//     </div>
//   );
// }

export default function MyAccounts() {
  const [totalBondAmount, setTotalBondAmount] = useState(0);
  const [totalTermAmount, setTotalTermAmount] = useState(0);
  const [totalIpoAmount, setTotalIpoAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [activeTab, setActiveTab] = useState("bonds");
  const bondsRef = useRef(null);
  const termsRef = useRef(null);
  const iposRef = useRef(null);
  const [indicatorStyle, setIndicatorStyle] = useState({});

  useEffect(() => {
    const activeRef =
      activeTab === "bonds"
        ? bondsRef
        : activeTab === "terms"
        ? termsRef
        : iposRef;
    if (activeRef.current) {
      const width = activeRef.current.offsetWidth;
      const left = activeRef.current.offsetLeft;
      setIndicatorStyle({ width, left });
    }
  }, [activeTab]);

  // Convert a string representation of a number to a JavaScript number
  function convertToNumber(stringAmount) {
    // Check if the input is already a number, and if not, convert it
    if (typeof stringAmount === "string") {
      return parseFloat(stringAmount.replace(",", "")); // Assuming ',' is the thousands separator
    } else if (typeof stringAmount === "number") {
      return stringAmount;
    }
    // Handle other cases if necessary
    return 0; // Default value if conversion fails
  }

  useEffect(() => {
    const user = getAuthUser();
    if (!user) {
      setIsLoading(false);
      return;
    }

    // Fetch and calculate total bond amount
    const fetchBonds = async () => {
      const bonds = await getBondsHoldings(user);
      if (bonds) {
        const totalBondAmount = calculateTotalBondAmount(bonds);
        setTotalBondAmount(totalBondAmount);
      }
    };

    // Fetch and calculate total term amount
    const fetchTerms = async () => {
      const terms = await getUserFixedTerm(user);
      if (terms) {
        const totalTermAmount = calculateTotalTermAmount(terms);
        setTotalTermAmount(totalTermAmount);
      }
    };

    // Fetch and calculate total IPO amount
    const fetchIpos = async () => {
      const ipos = await getUserIpos(user);
      if (ipos) {
        const totalIpoAmount = calculateTotalIpoAmount(ipos);
        setTotalIpoAmount(totalIpoAmount);
      }
    };

    // Fetch data and calculate totals
    Promise.all([fetchBonds(), fetchTerms(), fetchIpos()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const calculateTotalBondAmount = (bonds) => {
    let totalAmount = 0;

    bonds.forEach((bond) => {
      if (bond.typeOfRequest === "buy") {
        totalAmount += convertToNumber(bond.amountRequested);
      } else if (bond.typeOfRequest === "sell") {
        totalAmount -= convertToNumber(bond.amountRequested);
      }
    });

    return totalAmount;
  };

  const calculateTotalTermAmount = (terms) => {
    let totalAmount = 0;

    terms.forEach((term) => {
      if (term.type === "deposit") {
        totalAmount += convertToNumber(term.principalAmount);
      } else if (term.type === "withdrawal") {
        totalAmount -= convertToNumber(term.principalAmount);
      }
    });

    return totalAmount;
  };

  const calculateTotalIpoAmount = (ipos) => {
    let totalAmount = 0;

    ipos.forEach((ipo) => {
      if (ipo.amountInvested) {
        totalAmount += convertToNumber(ipo.amountInvested);
      }
    });

    return totalAmount;
  };

  return (
    <div className="myAccounts_page">
      <div className="section_body">
        <div className="account_cards">
          <div className="account_card">
            <div className="card_icon">
              <CiMoneyBill size={60} fill="#fff" />
            </div>
            <div className="card_texts">
              <h4 className="card_label">Balance</h4>
              {isLoading ? (
                <div className="spinner"></div>
              ) : (
                <strong className="card_amount">$ 0.00</strong>
              )}
            </div>
          </div>
          <div className="account_card">
            <div className="card_icon">
              <BsCardChecklist size={50} fill="#fff" />
            </div>
            <div className="card_texts">
              <h4 className="card_label">Bonds Account</h4>
              {isLoading ? (
                <div className="spinner"></div>
              ) : (
                <strong className="card_amount">
                  $ {formatNumber(totalBondAmount) || 0}
                </strong>
              )}
            </div>
          </div>
          <div className="account_card">
            <div className="card_icon">
              <CiMoneyCheck1 size={60} fill="#fff" />
            </div>
            <div className="card_texts">
              <h4 className="card_label">Terms Deposit</h4>
              {isLoading ? (
                <div className="spinner"></div>
              ) : (
                <strong className="card_amount">
                  $ {formatNumber(totalTermAmount) || 0}
                </strong>
              )}
            </div>
          </div>
          <div className="account_card">
            <div className="card_icon">
              <PiBriefcase size={50} fill="#fff" />
            </div>
            <div className="card_texts">
              <h4 className="card_label">IPOs Balance</h4>
              {isLoading ? (
                <div className="spinner"></div>
              ) : (
                <strong className="card_amount">
                  $ {formatNumber(totalIpoAmount) || 0}
                </strong>
              )}
            </div>
          </div>
        </div>
        <section className="holdings_section">
          <div className="tab_header">
            <h2
              ref={bondsRef}
              className={`title ${activeTab === "bonds" ? "active" : ""}`}
              onClick={() => setActiveTab("bonds")}
            >
              My Bonds
            </h2>
            <h2
              ref={termsRef}
              className={`title ${activeTab === "terms" ? "active" : ""}`}
              onClick={() => setActiveTab("terms")}
            >
              My Term Deposit
            </h2>
            <h2
              ref={iposRef}
              className={`title ${activeTab === "ipos" ? "active" : ""}`}
              onClick={() => setActiveTab("ipos")}
            >
              My IPOs
            </h2>
            <div className="indicator" style={indicatorStyle}></div>
          </div>
          {activeTab === "bonds" && <BondsTable />}
          {activeTab === "terms" && <TermsTable />}
          {activeTab === "ipos" && <IposTable />}
        </section>
      </div>
    </div>
  );
}
