import React, { useEffect, useState } from "react";
import logo from "../../../assets/white_logo.png";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import "./style.css";

export default function WelcomeScreen() {
  const [user, setUser] = useState(null);
  const [secondaryUser, setSecondaryUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Try to get user's first name from secureStorage
    const storedFirstName = secureLocalStorage.getItem("user_firstName");
    const storedSecondaryHolderName = secureLocalStorage.getItem(
      "secondaryHolderName"
    );
    if (storedFirstName && storedSecondaryHolderName) {
      // User details found in secureStorage
      setUser({ firstName: storedFirstName });
      setSecondaryUser({ secondaryHolderName: storedSecondaryHolderName });
      return;
    }

    // If code reaches here, it means the user's first name is not in secureStorage
    // You can fetch it from your database and then store it in secureStorage for future use

    const fetchUser = async () => {
      // Try to get user's first name and secondary account holder name from secure storage
      const storedFirstName = secureLocalStorage.getItem("user_firstName");
      const storedSecondaryHolderName = secureLocalStorage.getItem(
        "secondaryHolderName"
      );
      setIsLoading(true);
      if (storedFirstName) {
        const userName =
          storedFirstName.charAt(0).toUpperCase() + storedFirstName.slice(1);
        setUser({ firstName: userName });
      }
      if (storedSecondaryHolderName) {
        const secondaryHolderName =
          storedSecondaryHolderName.charAt(0).toUpperCase() +
          storedSecondaryHolderName.slice(1);
        setSecondaryUser({ secondaryHolderName: secondaryHolderName });
      }
      setIsLoading(false);
    };

    fetchUser();
  }, []);

  return (
    <section className="welcomeScreen">
      <div className="welcome_container">
        <img src={logo} alt="Logo" className="logo" />
        <div className="header">
          <h2 className="title">{user?.firstName}</h2>
          {secondaryUser && (
            <h2 className="title">{secondaryUser.secondaryHolderName}</h2>
          )}
        </div>
        <div className="body">
          <Link to="/dashboard/">
            <button className="btn">Explore your Account</button>
          </Link>
        </div>
      </div>
    </section>
  );
}
