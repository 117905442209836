// ResetPasswordForm.js
import React, { useEffect } from "react";
import logo from "../../../assets/white_logo.png";



export default function ResetPasswordForm({
  state,
  dispatch,
  handleChangePassword,
  showTooltip,
  setShowTooltip,
  ACTIONS,
}) {
  useEffect(() => {
    if (state.error) {
      const clearErrorTimeout = setTimeout(() => {
        dispatch({ type: ACTIONS.SHOW_ERROR, error: "" });
      }, 3000);
      return () => clearTimeout(clearErrorTimeout);
    }
  }, [state.error, dispatch, ACTIONS]);

  return (
    <section className="resetPassword_page">
      <div className="resetPassword_form">
        <img src={logo} alt="Logo" className="logo" />
        <div className="header">
          <h1 className="title">Set Password</h1>
          <p className="subtitle">
            Kindly enter your new password to activate your account and log in.
          </p>
          <span
            className="help_icon"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            ?
            {showTooltip && (
              <div className="tooltip">
                <strong>Password Requirements:</strong>
                <ul>
                  <li>At least 8 characters long</li>
                  <li>One uppercase letter</li>
                  <li>One lowercase letter</li>
                  <li>One number</li>
                  <li>One special character (!@#$%^&*)</li>
                </ul>
              </div>
            )}
          </span>
        </div>

        <form className="form_wrap" onSubmit={handleChangePassword}>
          <input
            type="password"
            name="password"
            placeholder="New Password"
            className="input_field"
            value={state.password}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.PASSWORD_CHANGE,
                field: "password",
                value: e.target.value,
              })
            }
            required
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm New Password"
            className="input_field"
            value={state.confirmPassword}
            onChange={(e) =>
              dispatch({
                type: ACTIONS.PASSWORD_CHANGE,
                field: "confirmPassword",
                value: e.target.value,
              })
            }
            required
          />
          
            {state.error && <p className="error_msg">{state.error}</p>}

          {state.loading ? (
            <button className="change_btn" type="submit" disabled>
              <div className="spinner"></div>
            </button>
          ) : (
            <button className="reset_btn" type="submit">
              Set Password
            </button>
          )}
        </form>
      </div>
    </section>
  );
}
