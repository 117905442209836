import React, { useEffect, useState } from "react";
import {
  addBankingDetails,
  getAuthUser,
  getBankingDetails,
  updateBankingDetails,
} from "../../firebase/firestore";
import "./style.css";
import Swal from "sweetalert2";

export default function BankingDetails() {
  const [formData, setFormData] = useState(
    {
      accountName: "",
      bankName: "",
      branch: "",
      bsbNumber: 0,
      accountNumber: 0,
    },
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchBankingDetails = async () => {
   setIsLoading(true);
    const user = getAuthUser();

    if (!user) {
      console.log("No user is currently authenticated.");
      return;
    }

    try {
      const data = await getBankingDetails(user);

      if (data.length > 0) {
        const bankingDetails = data[0];
        setFormData({
          accountName: bankingDetails.accountName,
          bankName: bankingDetails.bankName,
          branch: bankingDetails.branch,
          bsbNumber: bankingDetails.bsbNumber,
          accountNumber: bankingDetails.accountNumber,
        });
      }
    } catch (error) {
      console.error("Error fetching banking details: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBankingDetails();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

   const user = getAuthUser();

    if (!user) {
      console.log("No user is currently authenticated.");
      return;
    }

    const isValidBSBNumber = /^(?:\d{3}-\d{3}|\d{6})$/.test(formData.bsbNumber);
    const isValidAccountNumber = /^\d{6,10}$/.test(formData.accountNumber);

    if (!isValidBSBNumber) {
      setError("BSB number must be 6 digits long.");
      setTimeout(() => {
        setError("");
      }, 4000);
      setIsLoading(false);
      return;
    }
    if (!isValidAccountNumber) {
      setError("Account number must be between 6 and 10 digits long.");
      setTimeout(() => {
        setError("");
      }, 4000);
      setIsLoading(false);
      return;
    }

    const bankingDetailsData = {
      accountName: formData.accountName,
      bankName: formData.bankName,
      branch: formData.branch,
      bsbNumber: formData.bsbNumber,
      accountNumber: formData.accountNumber,
    };

    try {
      const userBankingDetails = await getBankingDetails(user);

      if (userBankingDetails && userBankingDetails.length > 0) {
        const docId = userBankingDetails[0].id; // Assuming the first document is the one to update
        await updateBankingDetails(user, docId, bankingDetailsData);
        Swal.fire ({
          icon: "success",
          title: "Banking details updated!",
          text: `Banking details updated successfully!`,
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        await addBankingDetails(user, bankingDetailsData);
        Swal.fire ({
          icon: "success",
          title: "Banking details updated!",
          text: `Banking details added successfully!`,
          showConfirmButton: false,
          timer: 2000,
        });
      }

      fetchBankingDetails(); // fetch details again after saving or adding
    } catch (error) {
      console.error("Error updating/adding banking details: ", error);
      Swal.fire ({
        icon: "error",
        title: "Oops...",
        text: `Failed to update/add banking details. Please try again.`,
        showConfirmButton: false,
        timer: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="banking_details">
      <div className="section_header">
        <h2 className="title">Banking Details</h2>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="9"
            viewBox="0 0 230 9"
          >
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="230"
              height="9"
              rx="4.5"
              fill="#688fb7"
            ></rect>
          </svg>
        </div>
      </div>

      <form action="" className="info_form" onSubmit={handleUpdate}>
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="account_name">Account Holder's Name:</label>
            <input
              type="text"
              name="account_name"
              className="input_field"
              value={formData.accountName}
              onChange={(e) =>
                setFormData({ ...formData, accountName: e.target.value })
              }
            />
          </div>
        </div>
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="bank_name">Bank Name:</label>
            <input
              type="text"
              name="bank_name"
              className="input_field"
              value={formData.bankName}
              onChange={(e) =>
                setFormData({ ...formData, bankName: e.target.value })
              }
            />
          </div>
          <div className="input_group">
            <label htmlFor="branch">Branch Address:</label>
            <input
              type="text"
              name="branch"
              className="input_field"
              value={formData.branch}
              onChange={(e) =>
                setFormData({ ...formData, branch: e.target.value })
              }
            />
          </div>
        </div>
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="bsbNumber">BSB Number:</label>
            <input
              type="text"
              name="bsbNumber"
              className="input_field"
              placeholder="6 digits"
              value={formData.bsbNumber}
              onChange={(e) =>
                setFormData({ ...formData, bsbNumber: e.target.value })
              }
            />
          </div>
          <div className="input_group">
            <label htmlFor="accountNumber">Account Number:</label>
            <input
              type="text"
              name="accountNumber"
              placeholder="6-10 digits"
              className="input_field"
              value={formData.accountNumber}
              onChange={(e) =>
                setFormData({ ...formData, accountNumber: e.target.value })
              }
            />
          </div>
        </div>
        {error && <p className="error_msg">{error}</p>}
        {isLoading ? (
          <button className="submit_btn" type="submit" disabled>
            <div className="spinner"></div>
          </button>
        ) : (
          <button className="submit_btn" type="submit">
            Update Details
          </button>
        )}
      </form>
    </section>
  );
}
