import React, { useEffect, useState } from "react";
import {
  getUserFixedTerm,
  withdrawFixedTerm,
  formatNumber,
  getCurrentDate,
  getAuthUser,
} from "../../../firebase/firestore";
import { getAuth } from "firebase/auth";
import FixedDepositModal from "../../Modals/fixedDepositModal";
import EarlyWithDrawalAert from "../../Modals/earlyWithdrawalAert";
import { auth } from "../../../firebase/firebase";
import Swal from "sweetalert2";
import "./style.css";

function TermsTable() {
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [termsData, setTermsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showEarlyWithdrawalAlert, setShowEarlyWithdrawalAlert] =
    useState(false);

  const handleDeposit = (term) => {
    setSelectedTerm(term);
    setShowDepositModal(true);
  };

  const userId = getAuthUser();

  const onWithdrawal = async () => {
    setIsLoading(true);
    try {
      // Check if there is a selectedFixedTerm before proceeding
      if (!selectedTerm) {
        console.error("No selected fixed term deposit.");
        return;
      }

      const withdrawal = {
        date: getCurrentDate(),
        status: "Pending",
        bankName: selectedTerm.bankName,
        term: selectedTerm.term,
        interestRate: selectedTerm.interestRate,
        type: "Early Withdrawal",
        logo: selectedTerm.logo,
      };
      await withdrawFixedTerm(userId, withdrawal);
      setShowEarlyWithdrawalAlert(false);
      Swal.fire({
        icon: "success",
        title: "Request Sent!",
        text: `"Thank you for your withdrawal request. Your account manager will be in touch shortly to confirm the details before this transaction is executed."`,
        showConfirmButton: false,
        timer: 4000,
      });
    } catch (error) {
      console.error("Error adding withdrawing transaction: ", error);
    }
    setIsLoading(false);
  };

  const handleWithdraw = (term) => {
    setSelectedTerm(term);
    setShowEarlyWithdrawalAlert(true);
  };

  useEffect(() => {
    setIsLoading(true); // Set loading status to true at the beginning


    if (!userId) {
      console.log("No user is currently authenticated.");
      setIsLoading(false); // Set loading status back to false
      return;
    }

    const fetchTerms = async () => {
      const terms = await getUserFixedTerm(userId);
      if (terms) {
        setTermsData(terms);
      }
    };
    fetchTerms();
    setIsLoading(false);
  }, []);

  const calculateMaturityAmount = (principal, interestRate, term) => {
    // Parse principal and interestRate to float numbers
    const principalNumber = parseFloat(principal);
    const interestRateNumber = parseFloat(interestRate);

    // Parse the term string to extract the number and the unit
    const termParts = term.split(" ");
    if (termParts.length !== 2) {
      console.error("Invalid term format:", term); // Log error for invalid term
      return "Invalid Input"; // Return error message for invalid term
    }

    let termNumber = parseFloat(termParts[0]);
    const termUnit = termParts[1];

    // If the term is in months, convert it to years
    if (termUnit.startsWith("Month")) {
      termNumber = termNumber / 12;
    } else if (!termUnit.startsWith("Year")) {
      console.error("Invalid term unit:", termUnit); // Log error for invalid termUnit
      return "Invalid Input"; // Return error message for invalid termUnit
    }

    // Check for invalid inputs
    if (
      isNaN(principalNumber) ||
      isNaN(interestRateNumber) ||
      isNaN(termNumber)
    ) {
      return "Invalid Input"; // Return error message for NaN values
    }

    // Calculate the maturity amount
    const maturityAmount =
      principalNumber * (1 + (interestRateNumber / 100) * termNumber);
    return maturityAmount.toFixed(2);
  };

  return (
    <div style={{ width: "100%", overflow: "scroll" }}>
      <table className="term_table">
        {termsData.length > 0 ? (
          <>
            <thead>
              <tr>
                <th title="Unique identifier or name.">Term Deposit Name</th>
                <th title="The initial amount deposited.">Principal Amount</th>
                <th title="The date when the term deposit will mature.">
                  Maturity Date
                </th>
                <th title="The rate at which interest is earned.">
                  Interest Rate
                </th>
                <th title="The amount receivable upon maturity.">
                  Maturity Amount
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            {termsData.map((term, index) => (
              <tbody key={index}>
                <tr>
                  <td>
                    <div className="button_grid">
                      <img src={term.logo} alt="logo" />
                      <p>{term.bankName}</p>
                    </div>
                  </td>
                  <td>$ {formatNumber(term.principalAmount)}</td>
                  <td>{term.term}</td>
                  <td>{term.interestRate} %</td>
                  <td>
                    ${" "}
                    {calculateMaturityAmount(
                      term.principalAmount,
                      term.interestRate,
                      term.term
                    ) || 0}
                  </td>
                  <td>
                    <div className="button_grid">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeposit(term);
                        }}
                        className="buy_button"
                      >
                        Renew
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleWithdraw(term);
                        }}
                        className="sell_button"
                      >
                        Withdraw
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </>
        ) : (
          <tbody>
            <tr>
              <td className="no_holding">No Fixed Terms Deposits Available.</td>
            </tr>
          </tbody>
        )}
      </table>
      {/* Deposit Terms Modal */}
      <FixedDepositModal
        isOpen={showDepositModal}
        onClose={() => setShowDepositModal(false)}
        fixedTerm={selectedTerm}
      />

      {/* Early Withdrawal Alert */}
      <EarlyWithDrawalAert
        isOpen={showEarlyWithdrawalAlert}
        onConfirm={onWithdrawal}
        term={termsData}
        onClose={() => setShowEarlyWithdrawalAlert(false)}
        isLoading={isLoading}
      />
    </div>
  );
}

export default TermsTable;
