import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {
  addUserRequestToFirestore,
  getAuthUser,
  getUser,
  updateUser,
} from "../../firebase/firestore";
import Swal from "sweetalert2";
import LoadingScreen from "../LoadingScreen";
import "./style.css";

export default function AccountInfo() {
  const [homePhone, setHomePhone] = useState("+353");
  const [mobilePhone, setMobilePhone] = useState("+353");
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      const userUID = getAuthUser();
      if (!userUID) {
        console.log("No UID found.");
        return;
      }
      try {
        const usersData = await getUser(userUID);
        if (usersData.length > 0) {
          const userData = usersData[0];
          setUser({
            ...userData,
            title: userData.title,
            fullName: userData.fullName,
            email: userData.email,
            street: userData.address,
            country: userData.country,
            jointAccount: userData.jointAccount,
            secondaryAccountHolder: userData.secondaryAccountHolder,
          });
          setHomePhone(userData.homePhone);
          setMobilePhone(userData.mobilePhone);
        }
      } catch (error) {
        console.log("Error fetching user data: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const userUID = getAuthUser();
    const userData = {
      fullName: user?.fullName || "",
      title: user?.title || "",
      email: user?.email || "",
      address: user?.street || "",
      city: user?.city || "",
      mobilePhone: mobilePhone || "",
      homePhone: homePhone || "",
      country: user?.country || "",
      postcode: user?.postcode || "",
      jointAccount: user?.jointAccount || false,
      secondaryAccountHolder: user?.secondaryAccountHolder || "",
      secondaryTitle: user?.secondaryTitle || "",
    };
    setIsLoading(true);
    try {
      if (user && userUID) {
        await updateUser(userUID, userData);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Account information updated!",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        addUserRequestToFirestore(userUID, ...Object.values(userData));
        setIsLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Account information added!",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    } catch (error) {
      console.log("Error updating/adding user data: ", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        showConfirmButton: false,
        timer: 2000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="account_info">
      <div className="section_header">
        <h2 className="title">Account Information</h2>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="180"
            height="9"
            viewBox="0 0 230 9"
          >
            <rect
              id="Rectangle_28"
              data-name="Rectangle 28"
              width="230"
              height="9"
              rx="4.5"
              fill="#688fb7"
            ></rect>
          </svg>
        </div>
      </div>
      {
        isLoading && (<LoadingScreen />)
      }
      <form action="" className="info_form" onSubmit={handleUpdate}>
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="title">Title for Primary Account Holder</label>
            <select
              name="title"
              className="form_select"
              value={user?.title || "select"} // Set the selected value here
              onChange={(e) => setUser({ ...user, title: e.target.value })} // Update the user state
            >
              <option value="select">--Select--</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Ms">Ms</option>
              <option value="Miss">Miss</option>
              <option value="Dr">Dr</option>
              <option value="Rev">Rev</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="input_group">
            <label htmlFor="first_name">Primary Account Holder:</label>
            <input
              type="text"
              name="first_name"
              className="input_field"
              value={user?.fullName || ""}
              onChange={(e) => setUser({ ...user, fullName: e.target.value })}
              title="Start with capital letter and use only letters"
              // pattern="^[A-Z][a-z]"
              required
            />
          </div>
        </div>
        {/* {user?.jointAccount && ( */}
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="secondaryTitle">Title for Secondary Account Holder</label>
            <select
              name="secondaryTitle"
              className="form_select"
              value={user?.secondaryTitle || "select"} // Set the selected value here
              onChange={(e) => setUser({ ...user, secondaryTitle: e.target.value })} // Update the user state
            >
              <option value="select">--Select--</option>
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Ms">Ms</option>
              <option value="Miss">Miss</option>
              <option value="Dr">Dr</option>
              <option value="Rev">Rev</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="input_group">
            <label htmlFor="second_name">Secondary Account Holder:</label>
            <input
              type="text"
              name="second_name"
              className="input_field"
              value={user?.secondaryAccountHolder || ""}
              onChange={(e) =>
                setUser({ ...user, secondaryAccountHolder: e.target.value })
              }
              title="Start with capital letter and use only letters"
            // pattern="^[A-Z][a-z]+$"
            />
          </div>
        </div>
        {/* )} */}
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="email">Email Address:</label>
            <input
              type="text"
              name="email"
              className="input_field"
              value={user?.email || ""}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
              required
            />
          </div>
        </div>
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="home_phone">Home Phone:</label>
            <PhoneInput
              id="home_phone"
              international
              defaultCountry="IE"
              value={homePhone}
              onChange={(phone) => {
                setHomePhone(phone);
              }}
              className="input_field"
            />
          </div>

          <div className="input_group">
            <label htmlFor="mobile_phone">Mobile Phone:</label>
            <PhoneInput
              id="mobile_phone"
              international
              defaultCountry="IE"
              value={mobilePhone}
              onChange={(phone) => {
                setMobilePhone(phone);
              }}
              className="input_field"
              required
            />
          </div>
        </div>

        <div className="form_group">
          <div className="input_group">
            <label htmlFor="street">Street:</label>
            <input
              type="text"
              name="street"
              className="input_field"
              value={user?.street || ""}
              onChange={(e) => setUser({ ...user, street: e.target.value })}
              required
            />
          </div>
          <div className="input_group">
            <label htmlFor="town/city">Town/City:</label>
            <input
              type="text"
              name="town/city"
              className="input_field"
              value={user?.city || ""}
              onChange={(e) => setUser({ ...user, city: e.target.value })}
              required
            />
          </div>
        </div>
        <div className="form_group">
          <div className="input_group">
            <label htmlFor="country">Country:</label>
            <input
              type="text"
              name="country"
              className="input_field"
              value={user?.country || ""}
              onChange={(e) => setUser({ ...user, country: e.target.value })}
              required
            />
          </div>
          <div className="input_group">
            <label htmlFor="postcode">Postcode:</label>
            <input
              type="text"
              name="postcode"
              className="input_field"
              value={user?.postcode || ""}
              onChange={(e) => setUser({ ...user, postcode: e.target.value })}
              required
              // pattern="^[A-Za-z0-9]{3} [A-Za-z0-9]{4}$"
              maxLength="8"
              title="Postcode must be in the format like D02X88"
            />
          </div>
        </div>
        {error && <p className="error_msg">{error}</p>}
        {isLoading ? (
          <button className="submit_btn" typeof="submit" disabled>
            <div className="spinner"></div>
          </button>
        ) : (
          <button className="submit_btn" typeof="submit">
            Update Account
          </button>
        )}
      </form>
    </section>
  );
}
