/*
* @license
* Copyright 2022 Google LLC
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
*/
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

// const USER_COLLECTION = "users";
const USERS_COLLECTION = "users";
const ADMIN_DASH_COLLECTION = "admin_users";
const USERS_REQUESTS = "userRequests";


// Authenticated user
export function getAuthUser() {
  const authInstance = getAuth();
  const user = authInstance.currentUser;

  if (!user) {
    return;
  }
  const authUser = user.uid;
  return authUser;
}

export function logoutAndRedirectToLogin() {
  const authInstance = getAuth();
  const user = authInstance.currentUser;
  
  if (!user) {
    console.log("No user is currently authenticated.");
    return;
  }
  
  // Sign out the user
  signOut(authInstance)
  .then(() => {
      const navigate = useNavigate();
      navigate('/')
    })
    .catch((error) => {
      console.error("Error signing out:", error);
    });
}

// Get Current Date
export function getCurrentDate () {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const day = String(now.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

// Format Number
export function formatNumber(number) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
}

export async function addUserRequestToFirestore(formData) {
  try {
    const adminDashRef = await addDoc(collection(db, ADMIN_DASH_COLLECTION), {});
  
    const userRequestRef = await addDoc(collection(db, ADMIN_DASH_COLLECTION, adminDashRef.id, USERS_REQUESTS), {
      ...formData,
    });
    return userRequestRef.id;
  } catch (error) {
    console.error("Error adding user request to Firestore:", error);
    return null;
  }
}

export async function getUser(uid) {
  const userRef = doc(db, USERS_COLLECTION, uid);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return [{ ...userSnap.data(), id: userSnap.id }];
  } else {
    return [];
  }
}

export function updateUser(uid, userData) {
  const userDoc = doc(db, USERS_COLLECTION, uid);
  return updateDoc(userDoc, userData);
}

export function deleteUser(uid) {
  const userDoc = doc(db, USERS_COLLECTION, uid);
  return deleteDoc(userDoc);
}

// Transactions
const TRANSACTIONS_SUB_COLLECTION = "transactions";

export function addTransaction(uid, date, amount, type, status, accountType) {
  // Add a transaction to the user-specific transactions sub-collection
  return addDoc(
    collection(db, USERS_COLLECTION, uid, TRANSACTIONS_SUB_COLLECTION),
    {
      date,
      amount,
      type,
      status,
      accountType,
    }
  );
}

export async function getTransactions(uid) {
  // Query the user-specific transactions sub-collection
  const transactionsQuery = query(
    collection(db, USERS_COLLECTION, uid, TRANSACTIONS_SUB_COLLECTION),
    orderBy("date")
  );
  const querySnapshot = await getDocs(transactionsQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no transactions are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

// Banking Details
export async function addBankingDetails(uid, data) {
  const bankingDetailsRef = collection(db, "users", uid, "bankingDetails");
  return addDoc(bankingDetailsRef, data);
}

export async function updateBankingDetails(uid, docId, data) {
  return setDoc(doc(db, "users", uid, "bankingDetails", docId), data);
}

export async function getBankingDetails(uid) {
  const firestorePath = `users/${uid}/bankingDetails`;

  const bankingDetailsQuery = query(collection(db, firestorePath));
  const querySnapshot = await getDocs(bankingDetailsQuery);

  if (querySnapshot.empty) {
    return []; // Return an empty array if no banking details are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

//BondsRequest
const BONDS_SUB_COLLECTION = "bonds";
const HOLDINGS_SUB_COLLECTION = "bondsHoldings";
const BONDS_REQUEST_SUB_COLLECTION = "bondsRequest";

export async function getBonds() {
  const bondsQuery = query(
    collection(db, BONDS_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(bondsQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no bonds are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

export async function getBondsHoldings(uid) {
  const bondsQuery = query(
    collection(db, USERS_COLLECTION, uid, HOLDINGS_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(bondsQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no bonds are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

export async function getBondsRequest(uid) {
  const bondsQuery = query(
    collection(db, USERS_COLLECTION, uid, BONDS_REQUEST_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(bondsQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no bonds are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

async function sendRequestAdmin(uid, bondData, typeOfRequest) {
  const userAdminDocRef = doc(db, `${ADMIN_DASH_COLLECTION}/${uid}`);
  // Ensure the document exists before adding to its subcollection
  await setDoc(
    userAdminDocRef,
    { createdAt: getCurrentDate() },
    { merge: true }
  );

  const bondsRequestPath = collection(
    db,
    `${ADMIN_DASH_COLLECTION}/${uid}/${BONDS_REQUEST_SUB_COLLECTION}`
  );
  return addDoc(bondsRequestPath, {
    ...bondData,
    requestStatus: "Pending",
    typeOfRequest: typeOfRequest,
  });
}

export async function buyBonds(uid, bondData) {
  return (
    // sendRequestToUser(uid, bondData, "buy"), // Assume you have a similar function for user
    sendRequestAdmin(uid, bondData, "buy")
  );
}

export async function sellBonds(uid, bondData) {
  return (
    // sendRequestToUser(uid, bondData, "sell"), // Assume you have a similar function for user
    sendRequestAdmin(uid, bondData, "sell")
  );
}

//NOTIFICATIONS
const NOTIFICATIONS_SUB_COLLECTION = "notifications";

export async function getNotificationsForUser(userId) {
  try {
    const notificationsRef = collection(
      db,
      USERS_COLLECTION,
      userId,
      NOTIFICATIONS_SUB_COLLECTION
    );
    const notificationsSnapshot = await getDocs(notificationsRef);

    if (notificationsSnapshot.empty) {
      return [];
    }

    const notifications = notificationsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
    return notifications;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return null;
  }
}

export const deleteNotification = async (uid, notificationId) => {
  try {
    const requestRef = doc(
      db,
      USERS_COLLECTION,
      uid,
      NOTIFICATIONS_SUB_COLLECTION,
      notificationId);
    await deleteDoc(requestRef);
  } catch (error) {
    console.error("Error deleting Notification: ", error);
    throw error;
  }
};

//FIXED TERM DEPOSIT
const FIXED_TERM_SUB_COLLECTION = "fixedTermDeposit";
const FIXED_TERMS_SUB_COLLECTION = "fixedTermDeposits";
const TERM_REQUEST_COLLECTION = "termDepositRequest";

export async function getFixedTerm() {
  const fixedTermQuery = query(
    collection(db, FIXED_TERM_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(fixedTermQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no fixedTerm are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

export async function getUserFixedTerm(uid) {
  const fixedTermQuery = query(
    collection(db, USERS_COLLECTION, uid, FIXED_TERMS_SUB_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(fixedTermQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no fixedTerm are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}

export async function depositFixedTerm(uid, newDeposit) {
  try {
    const userAdminDocRef = doc(db, `${ADMIN_DASH_COLLECTION}/${uid}`);
    await setDoc(
      userAdminDocRef,
      { createdAt: getCurrentDate() },
      { merge: true }
    );

    const fixedTerm_RequestPath = collection(
      db,
      `${ADMIN_DASH_COLLECTION}/${uid}/${TERM_REQUEST_COLLECTION}`
    );

    return addDoc(fixedTerm_RequestPath, {
      ...newDeposit,
    });
  } catch (error) {
    console.error("Error during deposit request: ", error);
    throw error; // or handle the error as per your needs.
  }
}

export async function withdrawFixedTerm(uid, fixedTermdData) {
  try {
    const userAdminDocRef = doc(db, `${ADMIN_DASH_COLLECTION}/${uid}`);
    await setDoc(
      userAdminDocRef,
      { createdAt: getCurrentDate() },
      { merge: true }
    );

    const fixedTerm_RequestPath = collection(
      db,
      `${ADMIN_DASH_COLLECTION}/${uid}/${TERM_REQUEST_COLLECTION}`
    );

    return addDoc(fixedTerm_RequestPath, {
      ...fixedTermdData,
    });
  } catch (error) {
    console.error("Error during withdrawal request: ", error);
    throw error; // or handle the error as per your needs.
  }
}

// IPOS
const IPOS_COLLECTION = "ipos";

//getIPOS
export const getIpos = async () => {
  const iposQuery = query(
    collection(db, IPOS_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(iposQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no ipos are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
};

//INVEST
export async function investInIPO(userId, investmentData) {
  try {
    // Check if a document with the user ID already exists
    const userDocRef = doc(db, ADMIN_DASH_COLLECTION, userId);
    const docSnap = await getDoc(userDocRef);

    // If it does not exist, create the document with the user ID
    if (!docSnap.exists()) {
      await setDoc(userDocRef, { userId }); // Set initial data as needed
    }

    // creating a reference to the investAmount sub-collection of the user’s document
    const investAmountCollectionRef = collection(
      userDocRef,
      "ipoInvestmentRequests"
    );

    // constructing the investment data object
    const investmentDataObj = {
      ...investmentData,
      status: "Pending", // Setting initial status as Pending
      timestamp: getCurrentDate(), // Adding a timestamp to know when the request was made
    };

    // adding the investment data to the investAmount sub-collection
    const docRef = await addDoc(investAmountCollectionRef, investmentDataObj);

    return docRef.id; // returning the id of the newly created document
  } catch (e) {
    console.error("Error adding document: ", e);
    throw e; // rethrowing the error after logging it
  }
}

//Get Users Ipos
export async function getUserIpos(uid) {
  const iposQuery = query(
    collection(db, USERS_COLLECTION, uid, IPOS_COLLECTION)
    // orderBy("date")
  );
  const querySnapshot = await getDocs(iposQuery);

  if (querySnapshot.empty) {
    return null; // Return null if no ipos are found
  }

  return querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
}
