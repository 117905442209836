import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/white_logo.png";
import {
  addUserRequestToFirestore,
  getCurrentDate,
} from "../../../firebase/firestore";
import Select from "react-select";
import "./style.css";

const validatePassword = (pass) => {
  const regex = /^(?=.*\d)(?=.*[\W_]).{8,}$/;
  return regex.test(pass);
};

const CountrySelect = ({ value, onChange }) => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);

  const handleCountryChange = (selectedOption) => {
    onChange({ target: { name: "country", value: selectedOption } });
  };

  return <Select options={countries} value={value} onChange={handleCountryChange} />;
};

export default function Register() {
  const initialFormState = {
    fullName: "",
    email: "",
    address: "",
    country: "",
    mobilePhone: "",
    password: "",
    confirmPassword: "",
    jointAccount: false,
    secondaryAccountHolder: "",
  };

  const [formData, setFormData] = useState(initialFormState);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const setErrorWithTimeout = (errorMessage) => {
    setError(errorMessage);
    setTimeout(() => {
      setError("");
    }, 4000);
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setErrorWithTimeout("Passwords do not match.");
      return;
    }

    if (!validatePassword(formData.password)) {
      setErrorWithTimeout(
        "Password must be at least 8 characters long, must contain at least one number and a special character."
      );
      return;
    }

    setIsLoading(true);

    const userRequest = {
      fullName: formData.fullName,
      email: formData.email,
      address: formData.address,
      country: formData.country.label,
      mobilePhone: formData.mobilePhone,
      password: formData.password,
      jointAccount: formData.jointAccount,
      secondaryAccountHolder: formData.secondaryAccountHolder,
      status: "pending",
      createdAt: getCurrentDate(),
    };


    try {
      const userRequestId = await addUserRequestToFirestore(userRequest);

      if (!userRequestId) {
        throw new Error("Failed to send signup request.");
      }

      setSuccessMessage("Your registration request has been sent. Please wait for admin approval.");
      setTimeout(() => {
        setSuccessMessage("");
        navigate("/");
      }, 4000);
    } catch (error) {
      setErrorWithTimeout(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Corrected placement of the return statement
  return (
    <section className="register_container">
      <div className="register_img">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="register_form">
        <img src={logo} alt="Logo" className="logo" />
        <div className="header">
          <h1 className="title">Register</h1>
          <p className="subtitle">Become a user by creating an account.</p>
        </div>
        <form className="form_wrap" onSubmit={handleSignup}>
          <div className="joint-account-container">
            <label className="joint-account-label" htmlFor="jointAccount">
              Joint Account
            </label>
            <input
              type="checkbox"
              name="jointAccount"
              checked={formData.jointAccount}
              onChange={handleChange}
              className="joint-account-checkbox"
            />
          </div>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Primary Account Holder Full Name"
            className="input_field"
            required
          />
          {formData.jointAccount && (
            <input
              type="text"
              name="secondaryAccountHolder"
              placeholder="Secondary Account Holder Full Name"
              className="input_field"
              value={formData.secondaryAccountHolder}
              onChange={handleChange}
            />
          )}
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="input_field"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="tel"
            name="mobilePhone"
            placeholder="Phone Number"
            className="input_field"
            value={formData.mobilePhone}
            onChange={handleChange}
            required
          />
          <div className="address_group">
            <CountrySelect value={formData.country} onChange={handleChange} />
            <input
              type="text"
              name="address"
              placeholder="Address"
              className="input_field"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <input
            type="password"
            name="password"
            placeholder="Password"
            className="input_field"
            value={formData.password}
            onChange={handleChange}
            required
            disabled={isLoading}
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirm Password"
            className="input_field"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            disabled={isLoading}
          />
          {error && <p className="error_msg">{error}</p>}
          {successMessage && <p className="success_msg">{successMessage}</p>}
          {isLoading ? (
            <button className="register_btn" type="submit" disabled>
              <div className="spinner"></div>
            </button>
          ) : (
            <button className="register_btn" type="submit">
              Register
            </button>
          )}
          <div className="login_info">
            <p className="text">Already have an account?</p>
            <Link to={"/"} className="login_text">
              Sign In
            </Link>
          </div>
        </form>
      </div>
    </section>
  );
}
