import React, { useEffect, useState } from "react";
import {
  formatNumber,
  getAuthUser,
  getUserIpos,
} from "../../../firebase/firestore";
import InvestIpoModal from "../Modals/InvestModal";
import "./style.css";

function IposTable() {
  const [showInvestModal, setShowInvestModal] = useState(false);
  const [selectedIpos, setSelectedIpos] = useState(null);
  const [iposData, setIposData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleDetails = (iposId) => {
    if (expandedRow === iposId) {
      setExpandedRow(null); // Close the currently open row
    } else {
      setExpandedRow(iposId); // Open the clicked row
    }
  };

  const handleInvest = (ipos) => {
    setSelectedIpos(ipos);
    setShowInvestModal(true);
  };

  useEffect(() => {
    setIsLoading(true); // Set loading status to true at the beginning

    const user = getAuthUser();

    if (!user) {
      console.log("No user is currently authenticated.");
      setIsLoading(false); // Set loading status back to false
      return;
    }

    const fetchIpos = async () => {
      const ipos = await getUserIpos(user);
      if (ipos) {
        setIposData(ipos);
      }
    };
    fetchIpos();
    setIsLoading(false);
  }, []);

  return (
    <div style={{ width: "100%", overflow: "scroll" }}>
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <table className="ipo_table">
          {iposData.length > 0 ? (
            <>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Purchase Date</th>
                  <th>Purchase Price</th>
                  <th>Number of Shares</th>
                  <th>Current Price</th>
                  <th>Actions</th>
                </tr>
              </thead>
              {iposData.map((ipos, index) => (
                <tbody key={index}>
                  <tr>
                    <td>
                      <div className="button_grid">
                        <img src={ipos.logo} alt="logo" />
                        <p>{ipos.name}</p>
                      </div>
                    </td>
                    <td>{ipos.date}</td>
                    <td>$ {formatNumber(ipos.amountInvested)}</td>
                    <td>{ipos.numberOfShares}</td>
                    <td>$ {formatNumber(ipos.sharePrice)}</td>
                    <td>
                      <div className="button_grid">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleInvest(ipos);
                          }}
                          className="buy_button"
                        >
                          Invest {/* Changed "Buy" to "Invest" */}
                        </button>
                        <button
                          onClick={() => toggleDetails(ipos.id)}
                          className="details_button"
                        >
                          Details
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
            </>
          ) : (
            <tbody>
              <tr>
                <td className="no_holding">No current Holdings.</td>
              </tr>
            </tbody>
          )}
        </table>
      )}
      {/* Invest Ipos Modal */}
      <InvestIpoModal
        isOpen={showInvestModal}
        onClose={() => setShowInvestModal(false)}
        ipo={selectedIpos}
      />
    </div>
  );
}

export default IposTable;
