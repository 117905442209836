import React, { useEffect, useState } from "react";
import { formatNumber, getAuthUser, getBondsHoldings } from "../../../firebase/firestore";
import SellBondsModal from "../Modal/SellBondsModal";
import BuyBondsModal from "../Modal/BuyBondsModal";
import { LiaAngleDoubleDownSolid } from "react-icons/lia";
import "./style.css";


function BondsTable() {
  const [showBuyModal, setShowBuyModal] = useState(false);
  const [showSellModal, setShowSellModal] = useState(false);
  const [selectedBond, setSelectedBond] = useState(null);
  const [bondsData, setBondsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);

  const toggleDetails = (bondId) => {
    if (expandedRow === bondId) {
      setExpandedRow(null); // Close the currently open row
    } else {
      setExpandedRow(bondId); // Open the clicked row
    }
  };
  const handleBuy = (bond) => {
    setSelectedBond(bond);
    setShowBuyModal(true);
  };
  
  const handleSell = (bond) => {
    setSelectedBond(bond);
    setShowSellModal(true);
  };
  
  useEffect(() => {
    setIsLoading(true); // Set loading status to true at the beginning
    
    const user = getAuthUser();
    
    if (!user) {
      setIsLoading(false); // Set loading status back to false
      return;
    }
    
    const fetchBonds = async () => {
      const bonds = await getBondsHoldings(user);
      if (bonds) {
        setBondsData(bonds);
      }
    };
    fetchBonds();
    setIsLoading(false);
  }, []);

  return (
    <div style={{ width: "100%", overflow: "scroll" }}>
      {isLoading ? (
        <div className="spinner"></div>
      ) : (
        <table className="bond_table">
          {bondsData.length > 0 ? (
            <>
              <thead>
                <tr>
                  <th title="The entity or organisation that has issued the bond.">
                    Issuer
                  </th>
                  <th title="International Securities Identification Number – a unique code that identifies a specific bond issue.">
                    ISIN Code
                  </th>
                  <th title="The number of units or face value of this bond you own.">
                    Holdings
                  </th>
                  <th title="The date on which the bond will mature and the principal amount will be repaid to bondholders.">
                    Maturity Date
                  </th>
                  <th title="Periodic interest payment made to bondholders, based on the bond's coupon rate.">
                    Coupon
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              {bondsData.map((bond, index) => (
                <tbody key={index}>
                  <tr>
                    <td>
                      <div className="button_grid">
                        <img src={bond.image} alt="logo" />
                        <p>{bond.issuerName}</p>
                      </div>
                    </td>
                    <td>{bond.isin}</td>
                    <td>
                      {/* {bond.currentValue } */}
                      1
                      </td>
                    <td>{bond.maturityDate}</td>
                    <td>{bond.couponRate} %</td>
                    <td>
                      <div className="button_grid">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBuy(bond);
                          }}
                          className="buy_button"
                        >
                          Buy
                        </button>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSell(bond);
                          }}
                          className="sell_button"
                        >
                          Sell
                        </button>
                        <button
                          onClick={() => toggleDetails(bond.id)}
                          className="details_button"
                        >
                          <LiaAngleDoubleDownSolid />
                        </button>
                      </div>
                    </td>
                  </tr>
                  {expandedRow === bond.id && (
                    <tr>
                      <td colSpan="6">
                        <div className="holding_dropdown">
                          <div className="dropdown_row">
                            <p className="bold_text">Sector:</p>
                            <span className="reg_text"> {bond.sector} </span>
                          </div>
                          <div className="dropdown_row">
                            <p className="bold_text">Coupon Frequency:</p>
                            <span className="reg_text">
                              {bond.couponFrequency}{" "}
                            </span>
                          </div>
                          <div className="dropdown_row">
                            <p className="bold_text">
                              Purchase Date and Price:
                            </p>
                            <span className="reg_text">
                              {bond.purchaseDate} at $ {formatNumber(bond.amountRequested)}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              ))}
            </>
          ) : (
            <tbody>
              <tr>
                <td className="no_holding">No current Holdings.</td>
              </tr>
            </tbody>
          )}
        </table>
      )}
      {/* Buy Bonds Modal */}
      <BuyBondsModal
        isOpen={showBuyModal}
        onClose={() => setShowBuyModal(false)}
        bond={selectedBond}
      />

      {/* Sell Bonds Modal */}
      <SellBondsModal
        isOpen={showSellModal}
        onClose={() => setShowSellModal(false)}
        bond={selectedBond}
      />
    </div>
  );
}

export default BondsTable;