import React, { useEffect, useState } from "react";
import { BsBriefcase, BsCardChecklist } from "react-icons/bs";
import { IoPersonOutline } from "react-icons/io5";
import { IoMdNotificationsOutline } from "react-icons/io";
import { CiMoneyBill, CiLogout, CiMoneyCheck1 } from "react-icons/ci";
import {
  formatNumber,
  getAuthUser,
  getBondsHoldings,
  getUserFixedTerm,
  getUserIpos,
} from "../../firebase/firestore";
import { PiBriefcase } from "react-icons/pi";
import Logo from "../../assets/white_logo.png";
import Logout from "../Modals/logout";
import { getAuth } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import "./style.css";

export default function Sidebar({ closeSidebar }) {
  const [totalBondAmount, setTotalBondAmount] = useState(0);
  const [totalTermAmount, setTotalTermAmount] = useState(0);
  const [totalIpoAmount, setTotalIpoAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  // const [totalAccountValue, setTotalAccountValue] = useState(0); // New state to manage total account value
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [secondaryUser, setSecondaryUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      // Try to get user's first name and secondary account holder name from secure storage
      const storedFirstName = secureLocalStorage.getItem("user_firstName");
      const storedSecondaryHolderName = secureLocalStorage.getItem(
        "secondaryHolderName"
      );
      setIsLoading(true);

      if (storedFirstName) {
        const userName =
          storedFirstName.charAt(0).toUpperCase() + storedFirstName.slice(1);
        setUser({ firstName: userName });
      }
      if (storedSecondaryHolderName) {
        const secondaryHolderName =
          storedSecondaryHolderName.charAt(0).toUpperCase() +
          storedSecondaryHolderName.slice(1);
        setSecondaryUser({ secondaryHolderName: secondaryHolderName });
      }
      setIsLoading(false);
    };

    fetchUser();
  }, []);

  useEffect(() => {
    // Calculate total balance
    const totalBalance = totalBondAmount + totalTermAmount + totalIpoAmount;
    setBalance(totalBalance);

  }, [totalBondAmount, totalTermAmount, totalIpoAmount]);

  // Convert a string representation of a number to a JavaScript number
  function convertToNumber(stringAmount) {
    // Check if the input is already a number, and if not, convert it
    if (typeof stringAmount === "string") {
      return parseFloat(stringAmount.replace(",", "")); // Assuming ',' is the thousands separator
    } else if (typeof stringAmount === "number") {
      return stringAmount;
    }
    // Handle other cases if necessary
    return 0; // Default value if conversion fails
  }

  useEffect(() => {
    const user = getAuthUser();
    if (!user) {
      setIsLoading(false);
      return;
    }

    // Fetch and calculate total bond amount
    const fetchBonds = async () => {
      const bonds = await getBondsHoldings(user);
      if (bonds) {
        const totalBondAmount = calculateTotalBondAmount(bonds);
        setTotalBondAmount(totalBondAmount);
      }
    };
    

    // Fetch and calculate total term amount
    const fetchTerms = async () => {
      const terms = await getUserFixedTerm(user);
      if (terms) {
        const totalTermAmount = calculateTotalTermAmount(terms);
        setTotalTermAmount(totalTermAmount);
      }
    };

    // Fetch and calculate total IPO amount
    const fetchIpos = async () => {
      const ipos = await getUserIpos(user);
      if (ipos) {
        const totalIpoAmount = calculateTotalIpoAmount(ipos);
        setTotalIpoAmount(totalIpoAmount);
      }
    };

    // Fetch data and calculate totals
    Promise.all([fetchBonds(), fetchTerms(), fetchIpos()]).then(() => {
      setIsLoading(false);
    });
  }, []);

  const calculateTotalBondAmount = (bonds) => {
    let totalAmount = 0;

    bonds.forEach((bond) => {
      if (bond.typeOfRequest === "buy") {
        totalAmount += convertToNumber(bond.amountRequested);
      } else if (bond.typeOfRequest === "sell") {
        totalAmount -= convertToNumber(bond.amountRequested);
      }
    });

    return totalAmount;
  };

  const calculateTotalTermAmount = (terms) => {
    let totalAmount = 0;

    terms.forEach((term) => {
      if (term.type === "deposit") {
        totalAmount += convertToNumber(term.principalAmount);
      } else if (term.type === "withdrawal") {
        totalAmount -= convertToNumber(term.principalAmount);
      }
    });

    return totalAmount;
  };

  const calculateTotalIpoAmount = (ipos) => {
    let totalAmount = 0;

    ipos.forEach((ipo) => {
      if (ipo.amountInvested) {
        totalAmount += convertToNumber(ipo.amountInvested);
      }
    });

    return totalAmount;
  };

  return (
    <aside className="sidebar_component">
      <div className="logo_container">
        <img src={Logo} alt="logo" />
      </div>

      {/* Menu */}
      <div className="menu_container">
        {/* Header */}
        <div className="menu_header">
          <h3>{user?.firstName}</h3>
          <h3>{secondaryUser ? secondaryUser.secondaryHolderName : ""}</h3>
          <p style={{ display: "grid" }}>
            <span>Total Account Value: </span>
            <span> $ {formatNumber(balance)}</span>
          </p>
        </div>

        {/* Body */}
        <div className="menu_body">
          <ul className="menu_items">
            <li className={`menu_list`}>
              <Link to="/dashboard" onClick={closeSidebar}>
                <BsBriefcase className="menu_icon" size={16} />
                <span>My Accounts</span>
              </Link>
            </li>
            <li className={`menu_list`}>
              <Link to="/dashboard/bonds" onClick={closeSidebar}>
                <BsCardChecklist className="menu_icon" size={18} />
                <span>Bonds</span>
              </Link>
            </li>

            <li className={`menu_list`}>
              <Link to="/dashboard/fixed-term-deposits" onClick={closeSidebar}>
                <CiMoneyCheck1 size={20} className="menu_icon" />
                <span>Fixed Term Deposits</span>
              </Link>
            </li>
            <li className={`menu_list`}>
              <Link to="/dashboard/ipos" onClick={closeSidebar}>
                <PiBriefcase className="menu_icon" size={20} />
                <span>IPOs</span>
              </Link>
            </li>
            <li className={`menu_list`}>
              <Link to="/dashboard/transactions" onClick={closeSidebar}>
                <CiMoneyBill className="menu_icon" size={20} />
                <span>Transactions</span>
              </Link>
            </li>
            <li className={`menu_list`}>
              <Link to="/dashboard/account-details" onClick={closeSidebar}>
                <IoPersonOutline className="menu_icon" size={18} />
                <span>Account Details</span>
              </Link>
            </li>
            {/* <li className={`menu_list`}>
              <Link to="/dashboard/interest-calculator" onClick={closeSidebar}>
                <AiOutlineCalculator className="menu_icon" size={18} />
                <span>Interest Calculator</span>
              </Link>
            </li> */}
            <li className={`menu_list`}>
              <Link to="/dashboard/notifications" onClick={closeSidebar}>
                <IoMdNotificationsOutline className="menu_icon" />
                <span>Notifications</span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* Logout */}
      <div className="menu_logout">
        <div className="menu_body">
          <div
            className="menu_list"
            onClick={() => {
              setIsLogoutModalOpen(true);
              closeSidebar();
            }}
          >
            <CiLogout className="menu_icon" />
            <span>Logout</span>
            <Logout
              isOpen={isLogoutModalOpen}
              isLoading={isLoading}
              onLogout={() => {
                setIsLoading(true);
                auth
                  .signOut()
                  .then(() => {
                    setIsLoading(false);
                    navigate("/");
                  })
                  .catch((error) => {
                    setIsLoading(false);
                    console.error("Error signing out:", error);
                  });
              }}
              onClose={() => setIsLogoutModalOpen(false)}
            />
          </div>
        </div>
      </div>
    </aside>
  );
}
