import React, { useState } from "react";
import { getAuthUser, getCurrentDate, sellBonds } from "../../../firebase/firestore";
import CurrencyInput from "react-currency-input-field";
import "./style.css";

export default function SellBondsModal({ isOpen, onClose, bond }) {
  const [bondsAmount, setBondsAmount] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSellBonds = async () => {
    const minimumInvestmentAmount = bond.minimumAmount;
    if (bondsAmount < minimumInvestmentAmount) {
      setError(`Cannot sell less than $${minimumInvestmentAmount.toFixed(2)}`);
      setTimeout(() => {
        setError("");
        setBondsAmount("");
      }, 4000);
      return;
    }
  
    const amountAsNumber = parseFloat(bondsAmount);
    const numberOfBondsToSell = amountAsNumber / minimumInvestmentAmount;
  
    const bondData = {
      amountRequested: amountAsNumber.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      image: bond.image,
      type: bond.type,
      couponRate: bond.couponRate.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      companyWebsite: bond.companyWebsite,
      isin: bond.isin,
      maturityDate: bond.maturityDate,
      saleDate: getCurrentDate(),
      currentValue: numberOfBondsToSell.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      issuerName: bond.issuerName,
      sector: bond.sector,
      couponFrequency: bond.couponFrequency,
      minimumAmount: bond.minimumAmount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      quantity: numberOfBondsToSell.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    };
  
    setIsLoading(true);
    const uid = getAuthUser();
    try {
      await sellBonds(
        uid,
        bondData
      );
      setMessage("Sale request has been sent.");
      setTimeout(() => {
        setMessage("");
        setBondsAmount("");
        onClose();
      }, 4000);
    } catch (error) {
      setError(`There was an issue sending request. Try again later.`);
      console.log(error.message);
      setTimeout(() => {
        setError("");
        setBondsAmount("");
      }, 4000);
    }
  
    setIsLoading(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal_overlay" onClick={(e) => e.stopPropagation()}>
      <div className="modal">
        <div className="section_header">
          <h2 className="title">{bond.issuerName}</h2>
          <div className="subtitle">
            <span>{bond.coupon}</span>
          </div>
        </div>
        <div className="section_body">
          <div className="more_dets">
            <div className="maturity_row">
              <p className="bold_text">Sector:</p>
              <span className="reg_text">{bond.sector}</span>
            </div>

            <div className="">
              {/* <div className="maturity_row">
                <p className="bold_text">Maturity Date:</p>
                <span className="reg_text">
                  {new Date(bond.maturityDate?.toDate()).toLocaleDateString()}
                </span>
              </div> */}
              <div className="maturity_row">
                <p className="bold_text">Minimum Amount:</p>
                <span className="reg_text">$ {bond.minimumAmount}</span>
              </div>
            </div>
          </div>
          <div className="input_group">
            <label htmlFor="title">Input Amount:</label>
            <CurrencyInput
              decimalSeparator="."
              prefix="$"
              name="bondsAmount"
              placeholder="$0"
              defaultValue={bondsAmount}
              decimalsLimit={2}
              onValueChange={(value) => {
                const formattedValue = parseFloat(value).toFixed(2);
                setBondsAmount(parseFloat(formattedValue)); // Store as a number
              }}
            />
          </div>
        </div>
        {message && <p className="success_msg">{message}</p>}
        {error && <p className="error_msg">{error}</p>}
        <div className="buttons_wrap">
          <button
            onClick={() => {
              handleSellBonds();
            }}
            className="submit_btn"
            >
            Request
          </button>
            {isLoading && <div className="spinner" style={{margin: "0 auto"}}></div> }
          <button onClick={onClose} className="cancel_btn">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
